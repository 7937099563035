import viewer from "./viewer";

export const MainStream = async (
  streamLive,
  setStreamLive,
  setViewCount,
  setStreamStats,
  robotId,
  streamId,
  streamView,
  setStreamView,
  rtcPeerConnection,
  setRtcPeerConnection
) => {
  const whepVideo = document.getElementById("stream");

  if (whepVideo) {
    whepVideo.muted = true;
    whepVideo.controls = false;

    await viewer(
      whepVideo,
      streamLive,
      setStreamLive,
      setViewCount,
      setStreamStats,
      robotId,
      streamId,
      streamView,
      setStreamView,
      rtcPeerConnection,
      setRtcPeerConnection
    );
  }
};
